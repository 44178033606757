
import React from 'react'
import photo_data from 'src/_data/lastcentury.json'
import { sortAlbums } from 'src/utils.js'
import Carousel from 'nuka-carousel';
import Img from 'gatsby-image'
import Container from '@material-ui/core/Container'
import tw, { styled } from 'twin.macro'
import { createGlobalStyle } from "styled-components"
import { Header } from 'src/components/Header'
import { Footer } from 'src/components/Footer'

const GlobalStyle = createGlobalStyle`
.prev-btn{
  display: none;
  @media screen and (min-width: 800px){
    display: block;
  }
}
.next-btn{
  display: none;
  @media screen and (min-width: 800px){
    display: block;
  }
}
.italic{
font-style:italic;
a{text-decoration: underlined;
&:hover{font-weight: bold;}}
}
body{margin:0}
p{
  font-family: 'Cormorant Garamond', serif;
}
.slider-control-bottomcenter{
  bottom: -3rem!important;
}
.gatsby-image-wrapper{
  display: block!important;
  margin: auto;
}
.text-center{
  text-align: center;
}
.page-content p{
	font-family: 'Cormorant Garamond', serif;
	font-size: 120%;
	text-align: justify;
    margin: 1em 1.5em 1em 1.5em;
}

.page-content{
  margin-top: -8rem!important;
	position: relative;
	background-color: #fff;
	width: 90%;
	max-width: 800px;
	margin: auto;
	z-index: 2;
	box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
	padding: 2em 0 5em 0;
}

@media (max-width: 400px){
	.page-content p{
	  font-size: 100%;
}
}
@media (max-width: 900px) {
	.page-content p{
  	font-size: 110%;
}
}

@media (min-width: 901px) { 
  .page-content p{
	  font-size: 120%;
  }
}
.page-title-wrapper {
  color: red;
  h2{
    text-align: center;
	font-family: 'Cinzel', serif;
	font-size: 2em;
	font-weight: normal;
	color: #8d929c;
    margin: 0.5em 0 1.5em 0;
}}
.page-content .text-center{
  text-align: center;
}
.slider-slide {
  :focus {outline: none!important;}
}


.bottom-page-image-wrapper{
	position: relative;
	z-index: 1;
	display: flex;
	flex-direction: column;
	width: 100%;
	/*height: 100%;*/
}

.bottom-page-image{
	align-self: flex-end;
	width: 100%;
    background: linear-gradient(ghostwhite 10%, rgba(0, 0, 0, 0) 35%);
}

.bottom-page-image img{
	position: relative;
	width: 100%;
	z-index: -1;
}

.top-page-image-wrapper{
	position: absolute;
	z-index: -5;
	top: 0;
	width: 100%;
}

.top-page-image{
	width: 100%;
    background: linear-gradient(rgba(248, 0, 0, 0) 42%, ghostwhite 73%, ghostwhite);
}

.top-page-image img{
	position: relative;
	width: 100%;
	z-index: -1;
}
`

const CarouselWrapper = styled.div`
width: 100%;
  
@media screen and (min-width: 800px){
  width: 750px;
}
  margin: auto;
  margin-bottom: 3rem;
`


const SlideWrapper = styled.div`
 ${tw`mb-8`}
`

const ImageWrapper = styled.div`
  height: calc(100% - 3rem);
  
`
const componentName = styled(Img)`
     picture > img {
    height: auto !important;
    }
    `
const LastCentury = ({ location, data }) => {
  
  const nodes = data.allFile.nodes
  
  const sortedAlbums = sortAlbums(nodes, photo_data)
  const bottom = nodes.filter(n=>n.relativePath.match('0004'))[0].childImageSharp.fluid
  const top = nodes.filter(n=>n.relativePath.match('0002'))[0].childImageSharp.fluid
  return (
    <>
      <GlobalStyle />

      <Container>
        <Header location={location} />
        <div className='page-content'>
          <div className='page-title-wrapper centered-text'>
            <h2>Last century</h2>
          </div>
          <p>The compte de Montcorps’ only child Emma married the comte de Savigny, who subsequently added Montcorps onto his own title. 
		Two generations of the de Savigny family enjoyed the estate, but the inheritance was complicated, and during the 1950s the property passed to a d'Armaillé godson of the Countess, who died, seemingly in a car accident in New York, before he ever moved into the house. An insight into the lives of the Savigny de Montcorps' lives in the 19th century can be read in the biography <span class='italic'>La Reine des Fleurs</span> by Alain de Savigny about a relation who used to visit Fertot at the height of its glory in 1880s, when she was developing a large perfume factory in the south.</p>
          <CarouselWrapper>
            <Carousel heightMode="current" defaultControlsConfig={{
              nextButtonText: '>',
nextButtonClassName: "next-btn",             
prevButtonText: '<',
prevButtonClassName:  "prev-btn",          
nextButtonStyle: {display
: sortedAlbums.filter(a => a.album === 1).length > 1 ? '' : 'none'},
  prevButtonStyle: {display: sortedAlbums.filter(a => a.album === 1).length > 1 ? '' : 'none'},
  pagingDotsStyle: {display: sortedAlbums.filter(a => a.album === 1).length > 1 ? '' : 'none'}
              }}>
              {sortedAlbums.filter(a => a.album === 1).map(a => <SlideWrapper>
                <ImageWrapper>
                  <Img fluid={a.data[0].childImageSharp.fluid}   imgStyle={{ objectFit: 'contain' }} imgStyle={{ objectFit: 'contain' }} />
                </ImageWrapper>
                <p className="text-center">{a.caption}</p>
              </SlideWrapper>)}
            </Carousel>
          </CarouselWrapper>
          <p>The chateau was abandoned in the early 1960s and most of the things which were left there were stolen. However, several photographs and documents, as a testament to the previous ownership, were found on the domain.</p> 
          <CarouselWrapper>
            <Carousel heightMode="current" defaultControlsConfig={{
              nextButtonText: '>',
nextButtonClassName: "next-btn",             
prevButtonText: '<',
prevButtonClassName:  "prev-btn",          
nextButtonStyle: {display
: sortedAlbums.filter(a => a.album === 2).length > 1 ? '' : 'none'},
  prevButtonStyle: {display: sortedAlbums.filter(a => a.album === 2).length > 1 ? '' : 'none'},
  pagingDotsStyle: {display: sortedAlbums.filter(a => a.album === 2).length > 1 ? '' : 'none'}
              }}>
              {sortedAlbums.filter(a => a.album === 2).map(a => <SlideWrapper>
                <ImageWrapper>
                  <Img fluid={a.data[0].childImageSharp.fluid}   imgStyle={{ objectFit: 'contain' }} />
                </ImageWrapper>
                <p className="text-center">{a.caption}</p>
              </SlideWrapper>)}
            </Carousel>
          </CarouselWrapper>

        </div>


      </Container>
          <div className='bottom-page-image-wrapper'>
            <div className='bottom-page-image'>
              <Img fluid={bottom} 
              imgStyle={{objectFit: 'cover'}}
              />
            </div>
          </div>
          <div className='top-page-image-wrapper'>
            <div className='top-page-image'>
              <Img fluid={top} 
              imgStyle={{objectFit: 'cover'}}
              />
            </div>
          </div>
      <Footer />
    </>
  )
}

export const data = graphql`
  query lastcentury {
          allFile(filter: {relativeDirectory: {eq: "history/lastcentury"}}) {
          nodes {
          id
        childImageSharp {
          fixed(width: 600, quality: 100) {
          ...GatsbyImageSharpFixed
        }
          fluid(maxWidth: 1600, quality: 80) {
          aspectRatio
          ...GatsbyImageSharpFluid
        }
        }
        relativePath
      }
    }
  }
  `

export default LastCentury
